/*::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}
::-webkit-scrollbar-thumb {
  background: RGB(0, 163, 235) !important;
}
::-webkit-scrollbar-track {
  background: rgb(255, 255, 255) !important;
}*/
.ui.segment {
  border: none !important;
  box-shadow: none !important;
  padding: 0.3em 0.3em !important;
}
.ui.card {
  margin: 0 auto !important;
}
/*.ui.button{
  margin: 1em auto !important;
  width: 80% !important;
  display: block !important;
}*/
.leftButton {
  width: 80%;
  margin: 1em auto !important;
  display: block !important;
}
.twoButton {
  width: 40%;
  margin:1em;
  display: inline-block ;
}

.ui.small.image {
  margin-bottom: 20px;
}
.ui.secondary.pointing.menu {
  border: none !important;
}
.ui.table tr td {
  border: none !important;
  padding: 0.3em 0.3em !important;
  font-weight: 300;
  text-align: left ;
}
.ui.table {
  text-align: left;
  border: none !important;
}
.active.item {
  border-color: #1895d6 !important;
}
.ui.styled.accordion .title {
  border: none !important;
}
.ui.input {
  width: 80% !important;
}
.ui.styled.accordion,
.ui.styled.accordion .accordion {
  box-shadow: none !important;
}
/* overrided styles from lib */

.fXAXjb {
  width: 2px !important;
}

/* overrided styles from lib */

.pane {
  width: 100%;
  height: 100vh;

  /* overflow-y: scroll !important;
overflow-x: scroll!important; */
}
.right {
  margin-left: 10px;
  height: 100%;
}
.content{
  height: 100%;
  width: 100%
}

.first {
  background-image: url("./assets/bg.jpg");
  color: white !important;
}
.third {
  overflow-y: scroll;
}
.layer {
  width: 100%;
  height: 100%;
  background: rgba(19, 59, 105, 0.281);
}
.second {
  /*  border-right: 2px solid #1895D655;*/
  position: relative;
}
.monthCard {
  width: 70%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  margin: 20px auto 20px auto;
}
.monthCard2 {
  width: 100%;
  text-align: left;
  margin: 0 auto;
}
.emphasize {
  font-weight: 600;
}
.monthDetail {
  text-emphasis: none;
  font-weight: 300;
}
.monthCard:hover {
  cursor: pointer;
  background: rgb(241, 240, 240);
}
.rowone {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2px;
}
.rowtwo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
}
.display-month {
  overflow-y: scroll;
  position: absolute;
  height: 50vh;
  width: 100%;
  bottom: 0;
  outline: none;
  /*border-top: 2px solid #1895D655;*/
}
.display-refmonth {
  overflow-y: scroll;
  position: relative;
  height: calc(100%);
  max-height: calc(100vh - 150px);
  width: 100%;
  bottom: 0;
  outline: none;
  border: 1px solid gray;
  border-radius: 3px;
  /*margin-top: 15px;*/
  padding:5px;
  /*border-top: 2px solid #1895D655;*/
}


.auth-button {
  margin: 0 auto;
}
.hoursEdit {
  
  outline: none;
  border: 0.5px solid gainsboro;
  width: auto;
}
.dateEdit {
  outline: none;
  border: 0.5px solid gainsboro;
  width: 100px;
}
.tunnit {
  outline: none;
  border: 0.5px solid gainsboro;
  width: 40px;
}
.uid {
  outline: none;
  border: 0.5px solid gainsboro;
  width: 100px;
}

.hoursIcon {
  cursor: pointer;
  float: right;
  margin-bottom:10px;
}
.addIcon{
  cursor: pointer;
  margin-left: 12px;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.csv-text-area {
  width: 100%;
  margin: 10px auto;
  height: 200px;
  outline: none;
  border: 0.4px solid black;
  font-family: monospace;
  white-space: pre;
}
.abcRioButtonContentWrapper {
  margin: 0 auto !important;
}
.abcRioButton {
  display: block !important;
}
.csv {
  outline: none;
  border: 0.2px solid rgb(219, 215, 215);
}
input {
  border: 0.5px solid gainsboro;
  outline: none;
}
.bold {
  font-weight: bold;
}
.col {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.bold {
  font-weight: bold;
  color: rgb(34, 33, 33);
  margin-left: 2%;
  padding: 0 5px 0 5px;
}
.project-card {
  overflow: scroll;
  height: 50%;
}

.activeMonthRow {
  text-align: left;
  background: linear-gradient(  #aaaadd,#1895d6);
  background-color: #1895d6;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
}
.normalMonthRow {
  text-align: left;
  background-color: #ffffff00;
  color: rgba(0, 0, 0, 0.8);
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
  margin-right: 5px;
}
.deactivatedMonthRow {
  text-align: left;
  background-color: #ffffff00;
  color: rgba(0, 0, 0, 0.3);
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.futureMonthRow{
  text-align: left;
  background-color: #ffffff00;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
  margin-right: 5px;

}
.normalMonthRow:hover {
  cursor: pointer;
}
.monthHeader {
  font-weight: 600;
  min-width: 80px;
  display: inline-block;
}

.glogin {
  bottom: 20px !important;
}
.ivcenter {
  margin: 1em auto !important;
}
.smalltext {
  font-weight: 300;
  font-size: -2pt;
}
.asiakkaat-ul {
  list-style: none;
  margin: 0;
  padding: 0;
  color: rgba(41, 40, 40, 0.8);
}
.asiakkaat-ul > li {
  display: inline-block;
  margin-right: 15px;
}
.tyototeuma-ul {
  display: flex;
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(41, 40, 40, 0.8);
}
.tyototeuma-ul > li {
  margin-right: 15px;
  width: 30%;
}

.projectHeader {
  font-weight: 600;
  min-width: 350px;
  display: inline-block;
}
.endedProjectHeader {
  font-weight: 300;
  min-width: 350px;
  display: inline-block;
}
.projectDetail {
  font-weight: 300;
}
.lightText {
  font-weight: 300;
}
.clientTotal {
  font-weight: 600;
  margin-left: 20px;
}
.clientRow {
  margin-bottom: 20px;
  margin-left: 20px;
}
.clientHeader {
  /*  font-weight: 600;*/
  font-weight: 400;
}
.clientDetail {
  font-weight: 300;
}
.clientStrong {
  /*font-weight: 600;*/
  font-weight: 600;
}
.projectInput {
  width: 100%;
  outline: none;
}
.tightProjectInput {
  outline: none;
}
.img__upload-btn {
  cursor: pointer;
}
.search-btn {
  margin-left: 24px;
  width: 100%;
}

/* styling  for  project accordion starts */
details {
  color:#5c5b5b;
  margin: 0 0 0.4em;
  padding: 1%;
  box-sizing:border-box;
}

summary {
  
  outline: none;
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 10px;
 
  position: relative;
  
}
summary::-webkit-details-marker {
  display: none;
}

summary >h3 {
  color:black;
}

/* styling  for  project accordion ends */
.accordion td {
  text-align: left;
  vertical-align: top;
  color:#464444;
  margin: 5px 20px 5px 20px;
  padding: 0px 20px 0px 20px; 
  overflow:visible;
}

.expenseEditCell{
  display:inline-block;
  padding:3px;
}
input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
}
th{
  text-align:left;
  margin-left: 0px;
  margin-right: 20px;
  padding: 0px 10px 0px 10px;
}
.numeric{
  text-align: right;
}
.select-style {
    font-weight: 500;
   
    cursor: pointer;
    border: none;
    color: white;
    width:88%;
    height: 35px;
    outline: none;
    border-radius: 4px;
    overflow: hidden;
    background: rgb(45, 141, 214);
    /*    -webkit-appearance:none;*/
}

.select-style select {
    padding: 5px 8px;
    text-align: center;
     margin: 0 auto;
    border: none;
    box-shadow: none;
   
}
.backdrop{
  width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.367);
}
.expense-modal{

   position: relative;
/*    height: 96vh;*/
    z-index: 500;
   text-align: center;
    max-width: 95vw;
    background: #ffffff;
    border-radius: 5px;
   margin: 30px auto;
   padding: 20px;
   display: inline-block;
/*   margin: 0 auto;*/
    
  
}

.ref-modal{

  position: relative;
/*    height: 96vh;*/
   z-index: 500;
  text-align: center;
   max-width: 95vw;
   max-height: 95vh;
   background: #ffffff;
   border-radius: 5px;
  margin: 30px auto;
  padding: 20px;
  display: inline-block;
/*   margin: 0 auto;*/
  width:100vw;
   
 
}

.exp-invoice{
  margin: 25px;
  display:inline-block;
/*  width: 90%;*/
/*  height: 90%;*/
  /*border:.3px solid #d1cccc;*/
  /*margin:0 auto;*/

}
:invalid{
  color: #ff0000;
}